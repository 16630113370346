import { useState } from 'react';
import { Alert, IconButton, Snackbar } from '@mui/material';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import ConfirmationDialog from 'components/ConfirmationDialog';
import useOrgMembersDelete from 'hooks/useOrgMembersDelete';

interface Props {
  user_id: string;
  user_email: string;
  org_id: string;
  org_name: string;
  mutateData: () => void | Promise<void>;
}

// TODO: handle exception
const RemoveMemberButton = ({ user_id, user_email, org_id, org_name, mutateData }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { trigger } = useOrgMembersDelete(org_id);
  const [failed, setFailed] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const removeMember = () => {
    trigger({ users: [user_id] })
      .then(() => {
        mutateData();
        setDialogOpen(false);
      })
      .catch(e => {
        setFailed(true);
        console.error(e);
      });
  };

  return (
    <>
      <IconButton
        color="secondary"
        aria-label="Remove from org"
        title="Remove user from organization"
        size="small"
        onClick={handleDialogOpen}>
        <PersonRemoveIcon fontSize="small" />
      </IconButton>
      <ConfirmationDialog
        title="Remove org member"
        description={
          <>
            Are you sure you want to remove <strong>{user_email}</strong> with ID{' '}
            <strong>{user_id}</strong> from <strong>{org_name}</strong>?
          </>
        }
        confirmationText="Remove"
        isOpen={dialogOpen}
        setOpen={setDialogOpen}
        confirmAction={removeMember}
      />
      <Snackbar open={failed} autoHideDuration={5000}>
        <Alert severity="error">Whoops! Something went wrong.</Alert>
      </Snackbar>
    </>
  );
};

export default RemoveMemberButton;
