import { useState, useEffect } from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { useNavigate } from 'react-router-dom';

import {
  TextField,
  IconButton,
  IconButtonProps,
  Stack,
  CircularProgress,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Collapse,
  Grid,
} from '@mui/material';

import { styled } from '@mui/material/styles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import useOrg from 'hooks/useOrg';
import useOrgUpdate from 'hooks/useOrgUpdate';
import useConnections from 'hooks/useConnections';
import useOrgDelete from 'hooks/useOrgDelete';
import IConnection from 'interfaces/Connection';
import ErrorDialog from './ErrorDialog';
import { FetcherError } from '../utils/fetcher';

interface Props {
  org_id: string;
  mutate_organizations: () => void;
  navigate: ReturnType<typeof useNavigate>;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const OrgCard = ({ org_id, mutate_organizations, navigate }: Props) => {
  const { org, isLoading, error, mutate } = useOrg(org_id);
  const { trigger: updateOrg, inProgress } = useOrgUpdate(org_id);
  const { trigger: deleteOrg } = useOrgDelete(org_id);
  const { connections } = useConnections(org_id);
  const [expanded, setExpanded] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingDisplayName, setIsEditingDisplayName] = useState(false);
  const [name, setName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [metadata_key, setMetadataKey] = useState('');
  const [metadata_value, setMetadataValue] = useState('');
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (org) {
      setName(org.name || '');
      setDisplayName(org.display_name || '');
    }
  }, [org]);

  const handleSave = async () => {
    try {
      await updateOrg({ name, display_name: displayName });
      mutate();
      setIsEditingName(false);
      setIsEditingDisplayName(false);
    } catch (err) {
      console.error('Failed to update org name/display name', err);
    }
  };

  const handleAddMetadata = async () => {
    try {
      const updatedMetadata = {
        ...org.metadata,
        [metadata_key]: metadata_value,
      };
      await updateOrg({ metadata: updatedMetadata });
      mutate();

      setMetadataKey('');
      setMetadataValue('');
    } catch (err) {
      console.error('Failed to update org metadata', err);
    }
  };

  const handleDeleteMetadata = async (keyToRemove: string) => {
    try {
      const updatedMetadata = { ...org.metadata };
      delete updatedMetadata[keyToRemove];
      await updateOrg({ metadata: updatedMetadata });
      mutate();
    } catch (err) {
      console.error('Failed to update org metadata', err);
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDelete = async () => {
    try {
      await deleteOrg(
        { id: org_id },
        {
          onSuccess: () => {
            navigate('/organizations');
            setOpenDialog(false);
            mutate_organizations();
          },
          onError: (error: unknown) => {
            setOpenDialog(false);
            if (error instanceof FetcherError) {
              // error.message is the same for all errors, see fetcher.tsx
              setErrorMessage(
                error.bodyText + ' (HTTP ' + error.status + ': ' + error.statusText + ')',
              );
              setOpenErrorDialog(true);
            } else {
              setErrorMessage('Unexpected error!');
              setOpenErrorDialog(true);
            }
          },
        },
      );
    } catch (err) {
      console.error('Failed to delete org', err);
    }
  };

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error != null) {
    console.error(error);
    return <p>error: {error.message}</p>;
  }

  return (
    <>
      <Card sx={{ minWidth: 275 }}>
        <CardHeader
          title={
            <Stack direction="row" alignItems="center" spacing={3}>
              <span>{org.display_name}</span>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  handleDialogOpen();
                }}
                color="secondary">
                <DeleteIcon />
              </IconButton>
            </Stack>
          }
          action={
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more">
              <ExpandMoreIcon />
            </ExpandMore>
          }
        />
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid container spacing={2}>
              <Grid xs={4}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} dense>
                  <ListSubheader component="div" id="details-subheader">
                    Details
                  </ListSubheader>
                  <ListItem>
                    <ListItemText primary="Org ID" secondary={org_id} />
                  </ListItem>
                  <ListItem
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => setIsEditingName(!isEditingName)}>
                        <EditIcon />
                      </IconButton>
                    }>
                    {isEditingName ? (
                      <TextField
                        value={name}
                        onChange={e => setName(e.target.value)}
                        onBlur={handleSave}
                        disabled={inProgress}
                        autoFocus
                      />
                    ) : (
                      <ListItemText primary="Name" secondary={name} />
                    )}
                  </ListItem>
                  <ListItem
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => setIsEditingDisplayName(!isEditingDisplayName)}>
                        <EditIcon />
                      </IconButton>
                    }>
                    {isEditingDisplayName ? (
                      <TextField
                        value={displayName}
                        onChange={e => setDisplayName(e.target.value)}
                        onBlur={handleSave}
                        disabled={inProgress}
                        autoFocus
                      />
                    ) : (
                      <ListItemText primary="Display Name" secondary={displayName} />
                    )}
                  </ListItem>
                </List>
              </Grid>
              <Grid xs={4}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} dense>
                  <ListSubheader component="div" id="connections-subheader">
                    Enabled Connections
                  </ListSubheader>
                  {connections?.map((conn: IConnection) => (
                    <ListItem key={conn.connection_id}>
                      {' '}
                      <ListItemText primary={conn.connection.name} />{' '}
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Grid xs={4}>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} dense>
                  <ListSubheader component="div" id="metadata-subheader">
                    Metadata
                  </ListSubheader>
                  {Object.entries(org.metadata || {}).map(([key, val]) => (
                    <ListItem
                      key={key}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDeleteMetadata(key)}>
                          <DeleteIcon />
                        </IconButton>
                      }>
                      <ListItemText primary={key} secondary={String(val)} />
                    </ListItem>
                  ))}
                  <ListItem
                    secondaryAction={
                      <IconButton edge="end" aria-label="add" onClick={handleAddMetadata}>
                        <AddIcon />
                      </IconButton>
                    }>
                    <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
                      <TextField
                        fullWidth
                        size="small"
                        id="metadata_key"
                        label="Key"
                        variant="outlined"
                        value={metadata_key}
                        onChange={e => setMetadataKey(e.target.value)}
                        disabled={inProgress}
                      />
                      <TextField
                        fullWidth
                        size="small"
                        id="metadata_value"
                        label="Value"
                        variant="outlined"
                        value={metadata_value}
                        onChange={e => setMetadataValue(e.target.value)}
                        disabled={inProgress}
                      />
                    </Stack>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>

      <ConfirmationDialog
        title="Delete Organization"
        description={
          <>
            Are you sure you want to delete organization <strong>{org.display_name}</strong> with ID{' '}
            <strong>{org_id}</strong>?
          </>
        }
        confirmationText="Delete"
        isOpen={openDialog}
        setOpen={setOpenDialog}
        confirmAction={handleDelete}
      />
      <ErrorDialog
        open={openErrorDialog}
        onClose={handleCloseErrorDialog}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default OrgCard;
