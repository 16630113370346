import { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Grid } from '@mui/material';
import {
  DataGrid,
  GridRenderCellParams,
  GridSortModel,
  GridEventListener,
  GridToolbar,
  GridFilterModel,
  GridRowSelectionModel,
  GridPaginationMeta,
} from '@mui/x-data-grid';
import AddUsersToOrgButton from '../../components/AddUsersToOrgButton';
import DeleteUserButton from '../../components/DeleteUserButton';
import DeleteUsersButton from '../../components/DeleteUsersButton';

interface UserRow {
  name: string;
  email: string;
  user_id: string;
  logins_count: number;
  last_login: string;
}

const UsersPage = () => {
  // Auth0
  const { getAccessTokenSilently } = useAuth0();
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  // Navigation
  const navigate = useNavigate();
  const { REACT_APP_API_BASE_URL } = process.env;

  // State management
  const [selectedOptions, setSelectedOptions] = useState<GridRowSelectionModel>([]);
  const [rows, setRows] = useState<UserRow[]>([]);
  const [paginationModel, setPaginationModel] = useState(() => {
    const savedModel = localStorage.getItem('usersPagePaginationModel');
    return savedModel ? JSON.parse(savedModel) : { page: 0, pageSize: 20 };
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [queryOptions, setQueryOptions] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    { field: 'name', headerName: 'Name', width: 300 },
    { field: 'email', headerName: 'Email', width: 300 },
    { field: 'user_id', headerName: 'User ID', width: 300 },
    { field: 'logins_count', headerName: 'Login counts', width: 150 },
    { field: 'last_login', headerName: 'Last login', width: 300 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<UserRow>) => (
        <DeleteUserButton
          user_id={params.row.user_id}
          user_email={params.row.email}
          mutateData={() => {
            setRows(prevRows => prevRows.filter(row => row.user_id !== params.row.user_id));
          }}
        />
      ),
    },
  ];

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    setSortModel(sortModel);
  }, []);

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    const query =
      Array.isArray(filterModel.quickFilterValues) &&
      filterModel.quickFilterValues.length > 0 &&
      filterModel.quickFilterValues[0].length > 2
        ? filterModel.quickFilterValues[0]
        : undefined;
    setQueryOptions(query);
  }, []);

  const onSelectionChange = (rowSelectionModel: GridRowSelectionModel) => {
    setSelectedOptions(rowSelectionModel);
  };

  const [hasNextPage, setHasNextPage] = useState<boolean | undefined>();
  const paginationMetaRef = useRef<GridPaginationMeta>();
  // Memoize pagination meta to avoid flickering
  // see https://mui.com/x/react-data-grid/pagination/
  const paginationMeta = useMemo(() => {
    if (hasNextPage !== undefined && paginationMetaRef.current?.hasNextPage !== hasNextPage) {
      paginationMetaRef.current = { hasNextPage };
    }
    return paginationMetaRef.current;
  }, [hasNextPage]);

  useEffect(() => {
    const fetcher = async () => {
      setIsLoading(true);
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: audience,
        },
      });

      // fetch data from server
      const params = {
        page: paginationModel.page.toString(),
        pageSize: paginationModel.pageSize.toString(),
        ...(sortModel[0] !== undefined && {
          sortField: sortModel[0]?.field,
          sorting: sortModel[0]?.sort?.toString(),
        }),
        ...(queryOptions !== undefined && {
          query: queryOptions.toString(),
        }),
      };

      const queryParams = new URLSearchParams(params).toString();

      const resp = await fetch(`${REACT_APP_API_BASE_URL}/users?${queryParams}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await resp.json();
      setRows(data);
      setHasNextPage(data.length >= paginationModel.pageSize);
      setIsLoading(false);
    };
    fetcher();
  }, [
    paginationModel,
    sortModel,
    queryOptions,
    REACT_APP_API_BASE_URL,
    audience,
    getAccessTokenSilently,
  ]);

  const visitUser: GridEventListener<'rowClick'> = (
    params, // GridRowParams
  ) => {
    navigate(`/users/${params.id}`);
  };

  // Get selected users with their details from the rows
  const selectedUsers = selectedOptions
    .map(selectedId => rows.find(row => row.user_id === selectedId))
    .filter((user): user is UserRow => user !== undefined)
    .map(user => ({
      id: user.user_id,
      name: user.name,
    }));

  return (
    <Box sx={{ height: '80vh', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item>
          <AddUsersToOrgButton users={selectedOptions} />
        </Grid>
        <Grid item>
          <DeleteUsersButton
            users={selectedUsers}
            mutateData={() => {
              setRows(prevRows =>
                prevRows.filter(row => !selectedUsers.map(user => user.id).includes(row.user_id)),
              );
              setSelectedOptions([]);
            }}
          />
        </Grid>
      </Grid>
      <DataGrid
        autoHeight
        sx={{ '.MuiDataGrid-row': { cursor: 'pointer' }, maxWidth: '99%' }}
        columns={columns}
        rows={rows}
        loading={isLoading}
        getRowId={(row: UserRow) => row.user_id}
        pagination
        paginationMeta={paginationMeta}
        rowCount={
          hasNextPage
            ? -1 // When there are more pages
            : paginationModel.page * paginationModel.pageSize + rows.length // On the last page, we know the exact total
        }
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={newModel => {
          setPaginationModel(newModel);
          localStorage.setItem('usersPagePaginationModel', JSON.stringify(newModel));
        }}
        pageSizeOptions={[10, 20, 30, 40, 50]}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        checkboxSelection
        keepNonExistentRowsSelected
        onRowSelectionModelChange={onSelectionChange}
        onRowClick={visitUser}
        disableColumnFilter
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </Box>
  );
};

export default UsersPage;
