import Stack from '@mui/material/Stack';
import RemoveMemberButton from './RemoveMemberButton';
import AddRolesToMemberButton from './AddRolesToMemberButton';

interface Props {
  user_id: string;
  user_email: string;
  org_id: string;
  org_name: string;
  mutate: () => void | Promise<void>;
}

const MemberActions = ({ org_name, org_id, user_id, user_email, mutate }: Props) => {
  return (
    <Stack direction="row" spacing={0.5}>
      <AddRolesToMemberButton user_id={user_id} org_id={org_id} org_name={org_name} />
      <RemoveMemberButton
        user_id={user_id}
        user_email={user_email}
        org_id={org_id}
        org_name={org_name}
        mutateData={mutate}
      />
    </Stack>
  );
};

export default MemberActions;
