import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import Shield from '@mui/icons-material/Shield';

import RoleUserTableRow from 'components/roles/RoleUserTableRow';
import AddRolesToUserButton from 'components/roles/AddRolesToUserButton';
import useUserRoles from 'hooks/useUserRoles';
import IRole from 'interfaces/Role';

interface Props {
  user_id: string;
  user_email: string;
}

const UserRolesTable = ({ user_id, user_email }: Props) => {
  const { roles, isLoading, error, mutate } = useUserRoles(user_id);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error != null) {
    console.error(error);
    return <p>error: {error.message}</p>;
  }

  return (
    <Card>
      <CardHeader
        avatar={<Shield />}
        title="Global Roles & Permissions"
        action={<AddRolesToUserButton user_id={user_id} mutateRoles={mutate} />}
      />
      <Divider />
      <CardContent>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Role</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.map((role: IRole) => (
              <RoleUserTableRow
                key={role.id}
                role={role}
                user_id={user_id}
                user_email={user_email}
                mutateRoles={mutate}
              />
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default UserRolesTable;
