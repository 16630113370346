import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import OrgCard from '../../components/OrgCard';
import MembersTable from '../../components/MembersTable';
import InvitationsTable from '../../components/InvitationsTable';
import useOrgs from 'hooks/useOrgs';

const OrgPage = () => {
  type Org = {
    id: string;
    name: string;
    [key: string]: unknown;
  };

  const params = useParams();
  const org_id: string = String(params.org_id);
  const [tab, setTab] = React.useState('members');
  const { orgs, mutate } = useOrgs();
  const navigate = useNavigate();
  const org_name = orgs?.find((org: Org) => org.id === org_id)?.display_name || org_id;

  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setTab(newTab);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <OrgCard org_id={org_id} mutate_organizations={mutate} navigate={navigate} />
      <br />
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '15px' }}>
          <Tabs value={tab} onChange={handleTabChange} aria-label="tabs">
            <Tab
              label="Members"
              id="org-tab-members"
              aria-controls="org-tabpanel-members"
              value="members"
            />
            <Tab
              label="Invitations"
              value="invitations"
              id="org-tab-invitations"
              aria-controls="org-tabpanel-invitations"
            />
          </Tabs>
        </Box>
        <div
          role="tabpanel"
          hidden={tab !== 'members'}
          id="org-tabpanel-members"
          aria-labelledby="org-tab-members">
          {tab === 'members' && <MembersTable org_id={org_id} org_name={org_name} />}
        </div>
        <div
          role="tabpanel"
          hidden={tab !== 'invitations'}
          id="org-tabpanel-invitations"
          aria-labelledby="org-tab-invitations">
          {tab === 'invitations' && <InvitationsTable org_id={org_id} />}
        </div>
      </Box>
    </Box>
  );
};

export default OrgPage;
