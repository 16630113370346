import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ConfirmationDialog from '../ConfirmationDialog';
import useUserRoleDelete from '../../hooks/useUserRoleDelete';
import IRole from '../../interfaces/Role';

interface Props {
  role: IRole;
  user_id: string;
  user_email: string;
  mutateRoles: () => void | Promise<void>;
}

const RemoveRoleButton = ({ role, user_id, user_email, mutateRoles }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { trigger } = useUserRoleDelete(user_id);
  const [failed, setFailed] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const removeRole = () => {
    trigger({ role: role.id })
      .then(() => {
        mutateRoles();
        setDialogOpen(false);
      })
      .catch(e => {
        setFailed(true);
        console.error(e);
      });
  };

  return (
    <>
      <IconButton
        color="secondary"
        aria-label="Remove role from user"
        title="Remove role from user"
        size="small"
        onClick={handleDialogOpen}>
        <RemoveModeratorIcon fontSize="small" />
      </IconButton>
      <ConfirmationDialog
        title="Remove role from user"
        description={
          <>
            Are you sure you want to remove the <strong>{role.name}</strong> role from{' '}
            <strong>{user_email}</strong> with ID <strong>{user_id}</strong>?
          </>
        }
        confirmationText="Remove"
        isOpen={dialogOpen}
        setOpen={setDialogOpen}
        confirmAction={removeRole}
      />
      <Snackbar open={failed} autoHideDuration={5000}>
        <Alert severity="error">Whoops! Something went wrong.</Alert>
      </Snackbar>
    </>
  );
};

export default RemoveRoleButton;
