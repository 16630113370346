import Stack from '@mui/material/Stack';
import { LinearProgress } from '@mui/material';
import useOrgMemberRoles from '../../hooks/useOrgMemberRoles';
import IRole from 'interfaces/Role';
import RoleChip from 'components/roles/RoleChip';

interface Props {
  user_id: string;
  user_email: string;
  org_id: string;
  fetched_roles?: IRole[];
}

const OrgMemberRoles = ({ user_id, user_email, org_id, fetched_roles }: Props) => {
  const { roles, error, isLoading, mutate } = useOrgMemberRoles(org_id, user_id, fetched_roles);

  if (isLoading) {
    return <LinearProgress />;
  }
  if (error != null) {
    console.log(error);
    return <p>error: {error.message}</p>;
  }

  return (
    <Stack direction="row" flexWrap="wrap" useFlexGap spacing={1}>
      {roles &&
        roles.map((role: IRole) => (
          <RoleChip
            key={role.id}
            role_id={role.id}
            org_id={org_id}
            user_id={user_id}
            user_email={user_email}
            role_name={role.name}
            mutateRoles={mutate}
          />
        ))}
    </Stack>
  );
};

export default OrgMemberRoles;
